import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { WipWelcomeComponent } from "./wip-welcome/wip-welcome.component";
import { WipContactComponent } from "./wip-contact/wip-contact.component";
import { ImprintComponent } from "./imprint/imprint.component";
import { DataProtectionComponent } from "./data-protection/data-protection.component";
import { WipServicesComponent } from "./wip-services/wip-services.component";

const routes: Routes = [
  {
    path: "",
    component: WipWelcomeComponent,
  },
  {
    path: "leistungen",
    component: WipServicesComponent,
  },
  {
    path: "kontakt",
    component: WipContactComponent,
  },
  {
    path: "impressum",
    component: ImprintComponent,
  },
  {
    path: "datenschutz",
    component: DataProtectionComponent,
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
