import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-wip-services",
  templateUrl: "./wip-services.component.html",
  styleUrls: ["./wip-services.component.scss"],
})
export class WipServicesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
